<template>
  <v-container fluid>
    <CustomerTopBar :global="true" />
    <v-divider
      class="mt-4"
      style="border-color: rgba(17, 42, 70, 0.14) !important"
    />
    <v-row class="mt-8">
      <customer-pie-charts
        :exportPermission="checkExportPermission($modules.clients.graph.slug)"
      ></customer-pie-charts>
    </v-row>
  </v-container>
</template>

<script>
import CustomerPieCharts from "@/components/Chart/CustomerPieCharts";
import CustomerTopBar from "@/views/Clients/CustomerTopBar.vue";
export default {
  components: {
    CustomerTopBar,
    CustomerPieCharts,
  },
  data() {
    return {
      btnShow: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.btnShow = true;
    }, 10);
  },
};
</script>

<style scoped>
.ptBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}
.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}
.personal-trainer_ic {
  background-image: url("../../assets/images/nav_icon/personal-trainer.png");
  background-position: center center;
  padding: 10px;
  background-size: contain;
}
</style>
