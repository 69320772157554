import { render, staticRenderFns } from "./CustomerGraph.vue?vue&type=template&id=f84ee234&scoped=true&"
import script from "./CustomerGraph.vue?vue&type=script&lang=js&"
export * from "./CustomerGraph.vue?vue&type=script&lang=js&"
import style0 from "./CustomerGraph.vue?vue&type=style&index=0&id=f84ee234&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f84ee234",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer,VDivider,VRow})
